<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceAnalysis-container">
    <el-dialog
      v-dialogDrag
      title="客服KPI详情"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="80%"
    >
      <div>
        <div class="common-screen-container">
          <div class="common-input-container">
            <span>所属店铺:</span>
            <el-select
              clearable
              filterable
              class="common-screen-input"
              v-model="params.shopId"
              @change="commonFun"
              placeholder="请选择"
            >
              <el-option
                v-for="item in shopListDict"
                :key="item.shopId + ''"
                :label="item.shopName"
                :value="item.shopId + ''"
              >
              </el-option>
            </el-select>
          </div>
          <div class="common-input-container">
            <span>基准值:</span>
            <el-select
            filterable
              class="common-screen-input"
              v-model="params.referenceType"
              @change="commonFun"
              placeholder="请选择"
            >
              <el-option key="other" label="客户" value="other"> </el-option>
              <el-option key="system" label="系统" value="system"> </el-option>
            </el-select>
          </div>
          <br />
          <!-- <div class="common-input-container">
            <el-date-picker
              v-model="params.datePicker"
              :clearable="false"
              @change="
                () => {
                  changeDate();
                }
              "
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          <div class="common-input-container">
            <el-radio-group v-model="params.type" @change="changeRadio">
              <el-radio-button :label="1">近7天</el-radio-button>
              <el-radio-button :label="2">近30天</el-radio-button>
              <el-radio-button :label="3">近90天</el-radio-button>
              <el-radio-button :label="0">昨天</el-radio-button>
            </el-radio-group>
          </div> -->
          <div class="common-input-container">
            <common-lately-date @commonGetDate="commonGetDate" :commonRadio="commonRadio" :startDate="params.startDate" :endDate="params.endDate" ref="commonReset"></common-lately-date>
          </div>
          <div class="common-input-container">
            <el-button
              type="text"
              size="small"
              @click="
                () => {
                  serviceKPICharts();
                }
              "
              >数据图表</el-button
            >
          </div>
          <div class="common-input-container">
            <el-button
              type="text"
              size="small"
              @click="
                () => {
                  serviceKPIDataTable();
                }
              "
              >数据汇总</el-button
            >
          </div>
        </div>
        <el-table class="common-table" :data="tableData" @sort-change="tableSort">
          <el-table-column
            fixed
            prop="excelDate"
            label="日期"
            :formatter="tableColumn"
            width="120px"
          ></el-table-column>
          <el-table-column
            fixed
            prop="subaccountName"
            label="员工账号"
            :formatter="tableColumn"
            width="120px"
          ></el-table-column>
          <el-table-column
            fixed
            prop="shopName"
            label="店铺名称"
            :formatter="tableColumn"
            width="160px"
          ></el-table-column>
          <el-table-column
            label="备注"
            prop="remark"
            :formatter="tableColumn"
            width="160px"
          >
          </el-table-column>
          <el-table-column
            prop="area"
            label="地区（分公司）"
            :formatter="tableColumn"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="loginPlatform"
            label="登录平台"
            :formatter="tableColumn"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="frequency"
            label="班次"
            :formatter="tableColumn"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="loginAccount"
            label="登录账号"
            :formatter="tableColumn"
            width="120px"
          ></el-table-column>
          <el-table-column
            sortable="custom"
            prop="consultationNum"
            label="总咨询量"
            :formatter="tableColumn"
            width="120px"
          ></el-table-column>
          <el-table-column
            label="首响(s)"
            sortable="custom"
            prop="firsrTime"
            width="120px"
          >
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.firstTimeFlag == '1' && scope.row.firsrTime ? 'warning' : ''
                "
                >{{ scope.row.firsrTime || "--" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="平响(s)" sortable="custom" prop="avgTime" width="120px">
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.avgTimeFlag == '1' && scope.row.avgTime ? 'warning' : ''
                "
                >{{ scope.row.avgTime || "--" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="问答比" sortable="custom" prop="qaRate" width="120px">
            <template slot-scope="scope">
              <span
                :class="scope.row.qaRateFlag == '0' && scope.row.qaRate ? 'warning' : ''"
                >{{
                  scope.row.qaRate
                    ? (scope.row.qaRate * 100).toFixed(2) == "NaN"
                      ? "--"
                      : (scope.row.qaRate * 100).toFixed(2) + "%"
                    : "--"
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="邀评率"
            sortable="custom"
            prop="inviteComments"
            width="120px"
          >
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.inviteCommentsFlag == '1' && scope.row.inviteComments
                    ? 'warning'
                    : ''
                "
                >{{
                  scope.row.inviteComments
                    ? (scope.row.inviteComments * 100).toFixed(2) == "NaN"
                      ? "--"
                      : (scope.row.inviteComments * 100).toFixed(2) + "%"
                    : "--"
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="responseRate"
            label="回复率"
            width="120px"
          >
            <template slot-scope="scope">
              <span>{{
                  scope.row.responseRate
                    ? (scope.row.responseRate * 100).toFixed(2) == "NaN"
                      ? "--"
                      : (scope.row.responseRate * 100).toFixed(2) + "%"
                    : "--"
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="conversionRate"
            label="转化率"
            width="120px"
          >
            <template slot-scope="scope">
              <span>{{
                  scope.row.conversionRate
                    ? (scope.row.conversionRate * 100).toFixed(2) == "NaN"
                      ? "--"
                      : (scope.row.conversionRate * 100).toFixed(2) + "%"
                    : "--"
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="满意度"
            sortable="custom"
            prop="satisfaction"
            width="120px"
          >
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.satisfactionFlag == '0' && scope.row.satisfaction
                    ? 'warning'
                    : ''
                "
                >{{ scope.row.satisfaction || "--" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="satisfactionDifference"
            :formatter="tableColumn"
            label="满意度差值"
            sortable="custom"
            width="120px"
          ></el-table-column>
          <el-table-column
            label="工单错误率"
            sortable="custom"
            prop="orderErrorRate"
            width="120px"
          >
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.orderErrorRateFlag == '1' && scope.row.orderErrorRate
                    ? 'warning'
                    : ''
                "
                >{{
                  scope.row.orderErrorRate
                    ? (scope.row.orderErrorRate * 100).toFixed(2) == "NaN"
                      ? "--"
                      : (scope.row.orderErrorRate * 100).toFixed(2) + "%"
                    : "--"
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="orderErrorRateDifference"
            :formatter="tableColumn"
            sortable="custom"
            label="工单错误率差值"
            width="160px"
          ></el-table-column>
          <el-table-column
            label="质检错误率"
            sortable="custom"
            prop="qualityErrorRate"
            width="120px"
          >
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.qualityErrorRateFlag == '1' && scope.row.qualityErrorRate
                    ? 'warning'
                    : ''
                "
                >{{
                  scope.row.qualityErrorRate
                    ? (scope.row.qualityErrorRate * 100).toFixed(2) == "NaN"
                      ? "--"
                      : (scope.row.qualityErrorRate * 100).toFixed(2) + "%"
                    : "--"
                }}</span
              >
            </template>
          </el-table-column>

          <el-table-column
            prop="qualityErrorRateDifference"
            :formatter="tableColumn"
            label="质检错误率差值"
            sortable="custom"
            width="160px"
          ></el-table-column>
          <el-table-column
            label="服务高压线"
            sortable="custom"
            prop="serviceHighVoltage"
            width="120px"
          >
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.serviceHighVoltageFlag == '1' && scope.row.serviceHighVoltage
                    ? 'warning'
                    : ''
                "
                >{{ scope.row.serviceHighVoltage || "--" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="遗漏客诉"
            sortable="custom"
            prop="missFeedback"
            width="120px"
          >
            <template slot-scope="scope">
              <span
                :class="
                  scope.row.missFeedback == '1' && scope.row.missFeedback ? 'warning' : ''
                "
                >{{ scope.row.missFeedback || "--" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="
                  () => {
                    addRemark(scope.row);
                  }
                "
                >编辑备注</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="(e) => pageFun(e, 'pageSize')"
          @current-change="(e) => pageFun(e)"
          style="text-align: center"
          :page-sizes="pagination.pageSizes"
          :page-size="params.pageSize"
          :current-page="params.page"
          layout="total, prev, pager, next,sizes, jumper"
          :total="pagination.total"
        >
        </el-pagination>
        <el-dialog
          v-dialogDrag
          title="备注"
          :append-to-body="true"
          :visible.sync="remarkVisible"
          width="520px"
        >
          <div>
            <el-form
              :model="remarkForm"
              ref="remarkForm"
              label-width="50px"
              class="demo-ruleForm"
            >
              <el-form-item> 异常备注，有异常备注的数据不参与汇总报表计算 </el-form-item>
              <el-form-item label="备注">
                <el-input
                  class="dialog-input"
                  type="textarea"
                  v-model="remarkForm.remark"
                  :autosize="{ minRows: 6, maxRows: 6 }"
                  maxLength="500"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button class="common-screen-btn" @click="remarkVisible = false"
              >取 消</el-button
            >
            <el-button
              class="common-screen-btn"
              type="primary"
              @click="
                () => {
                  submitRemark();
                }
              "
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <el-dialog
          v-dialogDrag
          :title="dialogType == 'charts' ? '客服KPI详情' : '数据汇总'"
          :append-to-body="true"
          :visible.sync="echartsVisible"
          width="60%"
        >
          <div>
            <div v-if="dialogType == 'charts'">
              <div id="echartsLineA"></div>
              <div id="echartsLineB"></div>
              <div id="echartsLineC"></div>
              <div id="echartsLineD"></div>
            </div>
            <div v-if="dialogType == 'table'">
              <div>
                <div>
                  <span class="tableDetail"
                    >客服名称： {{ subaccountName || "--" }}；</span
                  >
                  <span class="tableDetail"
                    >带号天数：
                    {{
                      (serviceDataInfo.days && serviceDataInfo.days + "天") || "--"
                    }}；</span
                  >
                  <span class="tableDetail"
                    >客服带号：
                    {{
                      serviceDataInfo.subaccounts && serviceDataInfo.subaccounts.length
                        ? serviceDataInfo.subaccounts.join("、")
                        : "--"
                    }}；</span
                  >
                  <span class="tableTip">
                    账号数据包含其他客服参与，因为有全天不换号情况</span
                  >
                </div>
                <table class="tradeData-table">
                  <tbody>
                    <tr>
                      <th>总咨询量</th>
                      <th>日均咨询量</th>
                      <th>首响最优</th>
                      <th>首响最差值</th>
                      <th>首响均值</th>
                    </tr>
                    <tr>
                      <td>{{ serviceDataInfo.consultationNum || "--" }}</td>
                      <td>{{ serviceDataInfo.dayConsultationNum || "--" }}</td>
                      <td>{{ serviceDataInfo.minimumFirstTime || "--" }}</td>
                      <td>{{ serviceDataInfo.maxirstTime || "--" }}</td>
                      <td>{{ serviceDataInfo.firsrTime || "--" }}</td>
                    </tr>
                    <tr>
                      <th>平响最优值</th>
                      <th>平响最差值</th>
                      <th>平响均值</th>
                      <th>满意度月均</th>
                      <th>当月收到评价总数</th>
                    </tr>
                    <tr>
                      <td>{{ serviceDataInfo.minimumAvgTime || "--" }}</td>
                      <td>{{ serviceDataInfo.maxAvgTime || "--" }}</td>
                      <td>{{ serviceDataInfo.avgTime || "--" }}</td>
                      <td>{{ serviceDataInfo.satisfaction || "--" }}</td>
                      <td>{{ serviceDataInfo.satisfactionNum || "--" }}</td>
                    </tr>
                    <tr>
                      <th>很不满意条数</th>
                      <th>很满意条数</th>
                      <th>邀评率</th>
                      <th>很不满条数占比</th>
                      <th>很满意条数占比</th>
                    </tr>
                    <tr>
                      <td>{{ serviceDataInfo.evaluateVeryDissatisfied || "--" }}</td>
                      <td>{{ serviceDataInfo.evaluateVerySatisfaction || "--" }}</td>
                      <td>
                        {{
                          (serviceDataInfo.inviteComments &&
                            serviceDataInfo.inviteComments * 100 + "%") ||
                          "--"
                        }}
                      </td>
                      <td>
                        {{
                          (serviceDataInfo.evaluateVeryDissatisfiedRate &&
                            serviceDataInfo.evaluateVeryDissatisfiedRate * 100 + "%") ||
                          "--"
                        }}
                      </td>
                      <td>
                        {{
                          (serviceDataInfo.evaluateVerySatisfactionRate &&
                            serviceDataInfo.evaluateVerySatisfactionRate * 100 + "%") ||
                          "--"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  serviceKPIDetail,
  addRemark,
  shopList,
  serviceKPICharts,
  serviceKPIDataTable,
} from "../../service/dataInfo.js";
import { tableColumn, DateTransform, getLatelyDay } from "../../utils/index.js";
import CommonLatelyDate from "../../components/common/commonLatelyDate.vue"
export default {
  components: { CommonLatelyDate },
  data() {
    return {
      loading: false,
      centerDialogVisible: false, // 自身dialog
      tableColumn,
      DateTransform,
      subaccountName: "", // 客服名称
      serviceDataInfo: "", // 数据汇总
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "店铺服务分析", isLink: true, url: "/data/customer-service-analysis" },
        {
          title: "店铺KPI",
          isLink: true,
          url: `/data/customer-service-analysis/shop-kpi?customerId=${this.$route.query.customerId}&customerName=${this.$route.query.customerName}&startDate=${this.$route.query.startDate}&endDate=${this.$route.query.endDate}&type=${this.$route.query.type}`,
        },
        {
          title: "客服KPI",
          isLink: true,
          url: `/data/customer-service-analysis/service-kpi?customerId=${this.$route.query.customerId}&customerName=${this.$route.query.customerName}&shopId=${this.$route.query.shopId}&shopName=${this.$route.query.shopName}&type=${this.$route.query.type}&startDate=${this.$route.query.startDate}&endDate=${this.$route.query.endDate}`,
        },
        { title: "客服KPI详情", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      shopListDict: [],
      remarkForm: {},
      remarkVisible: false, // 备注dialog
      echartsVisible: false, // 图表dialog
      commonRadio: this.$route.query.commonRadio,
      params: {
        adminId: this.$route.query.adminId,
        startDate: this.$route.query.startDate,
        endDate: this.$route.query.endDate,
        page: 1,
        pageSize: 10,
      },
      dialogType: "",
      tableData: [],
      pickerOptions: {},
    };
  },
  created() {
    // this.serviceKPIDetail();
  },
  methods: {
    commonGetDate (startTime, endTime, commonRadio) {
      this.params.startDate = startTime
      this.params.endDate = endTime
      this.commonRadio = commonRadio
      this.serviceKPIDetail()
    },
    async shopList(adminId) {
      let resData = (await shopList({ adminId, companyId: this.$route.query.companyId }))
        .data;
      this.shopListDict = resData;
    },
    getParams(params, adminId, subaccountName, commonRadio) {
      this.centerDialogVisible = true;
      this.shopList(adminId);
      this.params = { ...params, page: this.params.page, pageSize: this.params.pageSize };
      this.subaccountName = subaccountName;
      this.commonRadio = commonRadio;
      if (this.$refs.commonReset) {
        this.$refs.commonReset.getDate(commonRadio, params.startDate, params.endDate )
      }
      this.serviceKPIDetail();
      
    },
    async addRemark(row) {
      // 添加备注信息
      if (row) {
        this.remarkForm = {
          id: row.id,
          remark: row.remark || "",
        };
        this.remarkVisible = true;
      }
    },
    async submitRemark() {
      let remarkForm = { ...this.remarkForm };
      await addRemark(remarkForm);
      this.$message.success("添加成功");
      this.serviceKPIDetail();
      this.remarkVisible = false;
    },
    tableSort(e) {
      // 表格项排序
      let params = { ...this.params };
      params.sortField = e.prop;
      console.log(e.prop);
      switch (e.order) {
        case "ascending":
          params.sortType = 1;
          break;
        case "descending":
          params.sortType = 2;
          break;
        default:
          params.sortType = null;
      }
      this.params = params;
      this.serviceKPIDetail();
    },
    changeDate() {
      // 选择时间时清空近几天类型选择
      this.params.type = null;
      this.serviceKPIDetail();
    },
    changeRadio(e) {
      let date;
      switch (e) {
        case 0:
          date = 0;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.params.datePicker = getLatelyDay(date);
      this.serviceKPIDetail();
    },
    changeBreadcrumb(num) {
      // 面包屑点击事件
      this.nowBreadcrumb = num;
    },
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.serviceKPIDetail();
    },
    handleSelectionChange(row) {
      console.log(row);
    },
    resetBtn() {
      this.params = {
        shopId: this.$route.query.adminId,
        type: this.$route.query.type, // 筛选类型 今天/30天/90天 ...
        datePicker: [
          new Date(this.$route.query.startDate),
          new Date(this.$route.query.endDate),
        ], // 时间范围选择-默认当天
        page: 1,
        pageSize: 10,
      };
      this.serviceKPIDetail();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.serviceKPIDetail();
    },
    async serviceKPIDetail() {
      // 子账号列表
      this.loading = true;
      let params = { ...this.params };
      if (params.datePicker && params.datePicker.length) {
        params.startDate = DateTransform(params.datePicker[0]);
        params.endDate = DateTransform(params.datePicker[1]);
      }
      if (!params.type) {
        delete params.type;
      }
      if (!params.sortType && params.sortType != 0) {
        delete params.sortType;
      }
      let resData = (await serviceKPIDetail({ ...params })).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
      this.loading = false;
    },
    async serviceKPIDataTable() {
      this.dialogType = "table";
      this.echartsVisible = true;
      let params = { ...this.params };
      if (params.datePicker && params.datePicker.length) {
        params.startDate = DateTransform(params.datePicker[0]);
        params.endDate = DateTransform(params.datePicker[1]);
      }
      if (!params.type) {
        delete params.type;
      }
      if (!params.sortType && params.sortType != 0) {
        delete params.sortType;
      }
      let resData = (await serviceKPIDataTable(params)).data;
      this.serviceDataInfo = resData;
    },
    async serviceKPICharts() {
      this.dialogType = "charts";
      this.echartsVisible = true;
      let params = { ...this.params };
      if (params.datePicker && params.datePicker.length) {
        params.startDate = DateTransform(params.datePicker[0]);
        params.endDate = DateTransform(params.datePicker[1]);
      }
      if (!params.type) {
        delete params.type;
      }
      if (!params.sortType && params.sortType != 0) {
        delete params.sortType;
      }
      let resData = (await serviceKPICharts(params)).data;
      let echartsDate = [];
      let echartsData = [];
      let arr = ["总咨询量", "首响（s）", "平响（s）", "满意度", "问答比", "邀评率", "回复率", "转化率"];
      let arrKey = [
        "consultationNum",
        "firsrTime",
        "avgTime",
        "satisfaction",
        "qaRate",
        "inviteComments",
        "responseRate",
        "conversionRate"
      ];
      // if (resData && resData.length) {
      resData.map((item) => {
        echartsDate.push(item.excelDate);
      });
      arr.map((arrItem, arrIndex) => {
        echartsData.push({
          data: [],
          name: arrItem,
          type: "line",
          markLine: {
            data: [],
          },
          // stack: "总量",
        });
        resData.map((item, index) => {
          let keyStr = "";
          switch (arrKey[arrIndex]) {
            case "inviteComments":
              keyStr = "inviteCommentsReference";
              break;
            case "qaRate":
              keyStr = "qaRateReference";
              break;
            case "firsrTime":
              keyStr = "firstTimeReference";
              break;
            case "avgTime":
              keyStr = "avgTimeReference";
              break;
            case "satisfaction":
              keyStr = "satisfactionReference";
              break;
          }
          if (arrKey[arrIndex] == "inviteComments" || arrKey[arrIndex] == "qaRate" || arrKey[arrIndex] == "responseRate" || arrKey[arrIndex] == "conversionRate" || (arrKey[arrIndex] == "satisfaction" && this.params.guestFlag != '0')) {
            echartsData[arrIndex].data.push((item[arrKey[arrIndex]] * 100).toFixed(2));
            if (index + 2 < resData.length && keyStr) {
              echartsData[arrIndex].markLine.data.push([
                {
                  symbol: "none",
                  coord: [index, Number(item[keyStr] * 100)],
                },
                {
                  symbol: "none",
                  coord: [index + 1, Number(resData[index + 1][keyStr] * 100)],
                },
              ]);
            } else if (index + 1 < resData.length && keyStr) {
              echartsData[arrIndex].markLine.data.push([
                {
                  symbol: "none",
                  coord: [index, Number(item[keyStr] * 100)],
                },
                {
                  symbol: "none",
                  label: {
                    position: "end",
                    formatter:
                      arrItem + " " + Number(resData[index + 1][keyStr] * 100) + "%",
                  },
                  coord: [index + 1, Number(resData[index + 1][keyStr] * 100)],
                },
              ]);
            }
          } else {
            echartsData[arrIndex].data.push(item[arrKey[arrIndex]]);
            if (index + 2 < resData.length && keyStr) {
              echartsData[arrIndex].markLine.data.push([
                {
                  symbol: "none",
                  coord: [index, Number(item[keyStr])],
                },
                {
                  symbol: "none",
                  coord: [index + 1, Number(resData[index + 1][keyStr])],
                },
              ]);
            } else if (index + 1 < resData.length && keyStr) {
              if (arrKey[arrIndex] == "firsrTime" || arrKey[arrIndex] == "avgTime") {
                echartsData[arrIndex].markLine.data.push([
                  {
                    symbol: "none",
                    coord: [index, Number(item[keyStr])],
                  },
                  {
                    symbol: "none",
                    label: {
                      position: "end",
                      formatter:
                        arrItem.split("（s）")[0] +
                        " " +
                        Number(resData[index + 1][keyStr]) +
                        "s",
                    },
                    coord: [index + 1, Number(resData[index + 1][keyStr])],
                  },
                ]);
              } else {
                echartsData[arrIndex].markLine.data.push([
                  {
                    symbol: "none",
                    coord: [index, Number(item[keyStr])],
                  },
                  {
                    symbol: "none",
                    label: {
                      position: "end",
                      formatter: arrItem + " " + Number(resData[index + 1][keyStr]),
                    },
                    coord: [index + 1, Number(resData[index + 1][keyStr])],
                  },
                ]);
              }
            }
          }
        });
      });
      // }
      let echartsDataA = echartsData.slice(0, 1);
      let echartsDataB = echartsData.slice(4);
      let echartsDataC = echartsData.slice(1, 3);
      let echartsDataD = echartsData.slice(3, 4);
      this.echartsLine("echartsLineA", echartsDataA, echartsDate, ["总咨询量"]);
      this.echartsLine("echartsLineB", echartsDataB, echartsDate, ["问答比", "邀评率", "回复率", "转化率"]);
      this.echartsLine("echartsLineC", echartsDataC, echartsDate, [
        "首响（s）",
        "平响（s）",
      ]);
      this.echartsLine("echartsLineD", echartsDataD, echartsDate, ["满意度"]);
    },
    echartsLine(byId, echartsData, echartsDate, items) {
      // 折线图
      let yAxis;
      if (byId == "echartsLineB") {
        yAxis = {
          type: "value",
          axisLabel: {
            formatter: "{value} %",
          },
        };
      } else if (byId == "echartsLineD") {
        if (this.params.guestFlag == '0') {
          yAxis = { type: "value", max: 5 };
        } else {
          yAxis = {
            type: "value",
            max: 100,
            axisLabel: {
              formatter: "{value} %",
            },
          };
        }
      } else {
        yAxis = { type: "value" };
      }
      let myChart = this.$echarts.init(document.getElementById(byId));
      let option;
      if (echartsDate && echartsDate.length) {
        option = {
          title: {
            text: byId == "echartsLineA" ? "总咨询量" : byId == "echartsLineB" ? '问答比/邀评率': byId == "echartsLineC" ? '首响（s）/平响（s）' : '满意度',
          },
          width: "80%",
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: items,
            right: '10%'
          },
          grid: {
            left: "3%",
            right: "6%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: echartsDate,
          },
          yAxis: yAxis,
          series: echartsData,
        };
      } else {
        option = {
          title: {
            text: "暂无数据",
            x: "center",
            y: "40%",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: echartsDate,
          },
          yAxis: {
            type: "value",
          },
          series: echartsData,
        };
      }

      myChart.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
#echartsLineA,
#echartsLineC {
  width: 49%;
  height: 280px;
  display: inline-block;
  margin: 20px 0 50px 0;
}
#echartsLineB,
#echartsLineD {
  width: 49%;
  display: inline-block;
  margin: 20px 0 50px 0;
  height: 280px;
}
.tableDetail {
  margin-left: 16px;
}
.tableTip {
  color: #aaa;
}
.tradeData-table {
  margin: 16px 0;
  display: table;
  width: 100%;
  border-collapse: collapse;
  td,
  th {
    text-align: center;
    border: 1px solid #c9c9c9;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
  th {
    background: #f5f5f5;
    height: 60px;
    line-height: 60px;
    font-weight: 600;
  }
  td {
    height: 60px;
    line-height: 60px;
  }
}
.warning {
  color: #f00;
}
.serviceAnalysis-container {
  text-align: left;
  .breadcrumbStyle {
    color: #00000073;
    font-weight: 500;
  }
  .breadcrumbCursor {
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}
</style>
